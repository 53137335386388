
import { defineComponent } from '@vue/runtime-core';
import { getSelected } from '../Chooser/helpers';

export default defineComponent({
    methods: {
        submit(){
            if(getSelected())
                this.$router.push(`${this.$store.state.chooser!.previousRoute.path}`);
        }
    }
});
